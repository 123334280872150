import React from "react"
import { Link } from "gatsby"

export default (props) => (
    <nav className="navigation">
        <Link to="/">Home</Link>
        <Link to="/about-me">About</Link>
        <Link to="/contact">Contact</Link>
    </nav>

)